.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    flex-basis: 34.5%;
    height: 100%;
    border-right: 1px solid var(--gray-border);
  }
}

.title {
  font-weight: bold;
  text-align: center;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin: 60px 15px 20px;
    font-size: 1.25rem;
    line-height: 1.4375rem;
  }

  @media screen and (min-width: 768px) {
    margin: 35px 35px 20px;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-height: 225px;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &Logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--gray-light);

    img {
      max-height: 40%;
      max-width: 75%;
      margin: 0 auto;
      object-fit: contain !important;
      filter: grayscale(100%);
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 25px 20px 30px;
  background-color: var(--gray-light);

  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Title {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--black);
  }

  &Value {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--black);
  }
}

.goBack {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-top: auto;
  height: 40px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.8125rem;
  background-color: transparent;
  color: var(--black);
  border: none;
  outline: none;
  transition: var(--transition);

  svg {
    path {
      fill: var(--black);
    }
  }

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-top: 35px;
    margin-left: 15px;

    & + .title {
      margin-top: 15px;
    }
  }

  @media screen and (min-width: 768px) {
    svg {
      margin-right: 5px;

      path {
        transition: var(--transition);
      }
    }
  }
}
